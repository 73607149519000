import React, { Fragment } from 'react';
import Properties from '../components/properties/properties';
import Heroprop from '../components/home/heroprop';



export default ()=> {

  return(
    <Fragment>
      <Heroprop />
      <Properties />
      
      
    </Fragment>
  )
}